import { Row, Col, Divider } from 'antd';
import { NextPage } from 'next';
import { CopyrightCircleOutlined, LockTwoTone } from '@ant-design/icons';
// import Image from './Image';

const BrandedFooter: NextPage = () => {
  const style = { background: '#0092ff', padding: '8px 0' };
  return (
    <div className="container">
      <style jsx>
        {`
          .container {
            padding: 24px;
            padding-top: 16px;
            text-align: center;
            background-color: black;
          }
          span {
            color: #d9d9d9;
            font-size: 18px;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
              'Noto Color Emoji';
          }
          div {
            color: #d9d9d9;
            font-size: 14px;
            text-align: left;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
              'Noto Color Emoji';
          }
        `}
      </style>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <div className="text">FAQ</div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div>Help Center</div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div>Terms of Use</div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div>Privacy</div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <div>Cookie Preferences</div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div>Contact Us</div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div>Jobs</div>
        </Col>
      </Row>

      <br />
      <Row gutter={4} justify="start" align="middle">
        <Col>
          <CopyrightCircleOutlined style={{ fontSize: '18px' }} />
        </Col>
        <Col>
          <span>2020 Coding Mastered</span>
        </Col>
        <Col>
          <Divider type="vertical" />
        </Col>
        <Col>
          <LockTwoTone style={{ fontSize: '18px' }} twoToneColor="#d9d9d9" />
        </Col>
        <Col>
          <span>Secured with SSL</span>
        </Col>
      </Row>
    </div>
  );
};
export default BrandedFooter;
